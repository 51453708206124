<template>
  <div class="seleTest xi" id="app">
    <hd-com></hd-com>
    <div class="content-wrap">
      <div class="content">
        <div class="top-div flex-start">
          <div class="left-img"></div>
          <div class="top-title">{{ $route.query.titleName }}</div>
        </div>
        <template v-if="!isStartTest">
          <!-- 添加测试人信息 -->
          <div class="addUser-div flex-center" v-if="addUser == 0">
            <div class="addUser-box">
              <div class="top-tip">为方便给您保存测试结果，建议你准确填写相关信息</div>
              <div class="center-form">
                <el-form ref="answerUser" :model="answerUser" label-width="170px" :rules="rules">
                  <el-form-item label="请输入您的姓名:" prop="answerUserName">
                    <el-input class="w-18" v-model="answerUser.answerUserName"></el-input>
                  </el-form-item>
                  <el-form-item label="选择您的性别:" prop="answerSex">
                    <el-radio-group v-model="answerUser.answerSex">
                      <el-radio :label="0">男</el-radio>
                      <el-radio :label="1">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="选择您的年龄:" prop="answerAge">
                    <el-radio-group v-model="answerUser.answerAge" class="age-radio">
                      <el-radio :label="0">6岁以下</el-radio>
                      <el-radio :label="1">7-12岁</el-radio>
                      <el-radio :label="2">13-17岁</el-radio>
                      <el-radio :label="3">18-45岁</el-radio>
                      <el-radio :label="4">46-69岁</el-radio>
                      <el-radio :label="5">69岁以上</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="请输入您的手机号:" prop="answerPhone">
                    <el-input class="w-18" v-model="answerUser.answerPhone"></el-input>
                  </el-form-item>
                  <el-form-item label="请输入您的基础病:" prop="diseaseName">
                    <el-input class="w-18" v-model="answerUser.diseaseName"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button class="btn1"  @click="addUser = 1"><span class="text">取消</span></el-button>
                    <el-button class="btn" @click="saveBtn('answerUser')"><span class="text">提交</span></el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <!-- 选择测试人 -->
          <div class="" v-if="addUser == 1">
            <div class="flex-end" style="margin: 10px auto 15px; width: 80%">
              <el-input class="searchInput" v-model="keyWord" style="width: 60%" placeholder="搜索测试对象..." clearable @keyup.enter.native="search"><i slot="prefix" class="el-input__icon el-icon-search"></i> </el-input>
            </div>
            <div class="selectUser-div flex-center">
              <div class="selectUser-box" style="width: 100%">
                <div class="userList-div flex-start flex-wrap">
                  <div class="item-div flex-center-columns" @click="addUser = 0">
                    <div class="addBtn">＋ 新增测试对象</div>
                  </div>
                  <div class="item-div flex-center-columns" v-for="(item, index) in answerUserList" :key="index" :class="{ active: selectAnswerUser.id == item.id }" @click="selectAnswerUser = item">
                    <div class="userName oneLines">{{ item.answerUserName }}</div>
                    <div class="userInfo">{{ item.answerSex ? "女" : "男" }} {{ item.answerAgeDesc }} {{ item.answerPhoneDesc }}</div>
                    <div class="userInfo">基础病：{{ item.diseaseName ? item.diseaseName : '--' }}</div>
                    <div class="topDel-div">
                      <i class="el-icon-edit" @click="editAnswerUser(item)" style="margin-right: 5px">编辑</i>
                      <i class="el-icon-delete" @click="delAnswerUser(item, index)">删除</i>
                    </div>
                  </div>
                </div>
                <div class="flex-end zsureBtn">
                  <el-button v-if="selectAnswerUser" class="btn1" @click="goAnswerStaging()"><span class="text">未完成({{ AnswerStagingList.length }})</span></el-button>
                  <el-button v-if="selectAnswerUser" class="btn" @click="startTest()"><span class="text">开始测试</span></el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="" v-if="addUser == 2" style="width: 100%">
          <div class="selectUser-div flex-center">
            <div class="selectUser-box">
              <div class="userList-div flex-wrap" style="display: flex;">
                <div class="item-div1" v-for="(item, index) in AnswerStagingList" :key="index">
                  <div class="flex-s-b">
                      <div class="" style="display: flex;align-items: center;">
                        <div class="titleBoxTextimage" @click.stop="goChooseCheck(item)">
                          <img :src="item.checked ? urlCheckM : noUrlCheckM" />
                        </div>
                        <div style="margin-left: 10px;" class="userNameBox">{{ item.answerUserInfo.answerUserName }}</div> 
                        <div class="userInfo">{{ item.answerUserInfo.answerSex ? "女" : "男" }} {{ item.answerUserInfo.ageContent }} {{ item.answerUserInfo.showanswerPhone }}</div>
                    </div>
                    <div class="delBox" @click.stop="gostartBox(item)">
                      继续
                    </div>
                  </div>
                  <div class="flex-s-b" style="margin-left: 35px;">
                    <div class="flex-s-b-r" >
                      <div class="" style="display: flex; align-items: center;">
                        <div class="userNameBox1" style="color:#009583;">{{item.questionIndex}}</div> 
                        <div class="userNameBox1">{{'/' + item.questionTotal}}</div> 
                        <div class="userInfo" style="margin-left: 10px;"> {{item.answerDate | timefilters}}</div>
                      </div>
                      <el-progress :percentage="item.percentnum"></el-progress>
                    </div>
                    
                    <div class="delBox1" @click.stop="godelBox(item)">
                      <i class="el-icon-delete"></i>
                      删除
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-end zsureBtn">
                <div style="display: flex;align-items: center;" @click="allSelect">
                  <div class="titleBoxTextimage" style="margin-right: 10px;">
                      <img :src="cssbtn ? urlCheckM : noUrlCheckM" />
                  </div>
                  全选
                </div>
         
                 <div class="delBox" @click.stop="goalldelBox" style="margin-left: 40px;">
                      删除
                  </div>
              </div>
              
            </div>
          </div>
        </div>
        </template>
        <!-- 试题 -->
        <div class="test-div flex-center" v-if="isStartTest">
          <div class="test-box">
            <div class="top-tip">请根据您的体验和感觉，回答以下问题：</div>
            <div class="testList-div">
              <div class="item-div" v-for="(item, index) in CurrentQuestionLst" :key="index">
                <div v-if="CurrentIndex == index">
                  <!-- 题目 -->
                  <div class="testTitle-bg">
                    <div class="quest-title">({{ index * 1 + 1 }}/{{ QuestionTotal }})【{{ item.type == "Radio" ? "单选" : "多选" }}】{{ item.content.text }}</div>
                    <div class="img-box flex-start flex-wrap" v-if="item.content.imgPath.length != 0 && item.content.imgPath != null">
                      <img :src="`${Services.Statics}${img}`" v-for="(img, i) in item.content.imgPath" :key="i" />
                    </div>
                  </div>
                  <div class="" v-if="item.type == 'Radio'">
                    <div v-for="(option, Oindex) in item.options" :key="Oindex" @click="handle(Oindex,option.id, item)">
                      <div class="question-option">
                        <div style="display: flex; align-items: center" class="">
                          <div class="titleBoxTextimage">
                            <img :src="item.optionId == option.id || curOindex == Oindex ? urlCheck : noUrlCheck" />
                          </div>
                          <div :class="item.optionId == option.id || curOindex == Oindex ? 'active titleBoxText' : 'titleBoxText'" style="flex: 1; width: 100%">
                            {{option.content.text}}
                          </div>
                        </div>
                      </div>
                      <div class="optionsImgflexbox" v-if="option.content.imgPath.length != 0 && option.content.imgPath != null">
                        <div class="optionsImgbox" v-for="(optionimg, indexoptionimg) in option.content.imgPath" :key="indexoptionimg">
                          <img :src="http + optionimg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="item.type == 'Multiple'">
                    <div v-for="(option, Oindex) in item.options" :key="Oindex" @click="handleMultiple(Oindex,option.id, item)">
                      <div class="question-option">
                        <div style="display: flex; align-items: center" class="">
                          <div class="titleBoxTextimage">
                            <img :src="option.checked ? urlCheck : noUrlCheck" />
                          </div>
                          <div :class="option.checked ? 'active titleBoxText' : 'titleBoxText'" style="flex: 1; width: 100%">
                            {{option.content.text}}
                          </div>
                        </div>
                      </div>
                      <div class="optionsImgflexbox" v-if="option.content.imgPath.length != 0 && option.content.imgPath != null">
                        <div class="optionsImgbox" v-for="(optionimg, indexoptionimg) in option.content.imgPath" :key="indexoptionimg">
                          <img :src="http + optionimg" />
                        </div>
                      </div>
                      <el-button  @click="handleMultipleSure(Oindex,option,item)" class="btn">
                        <span class="text"> 确定(可多选)</span>
                      </el-button>
                    </div>
                  </div>
                  <!-- 选项 -->
                  <!-- <div class="test-option">
                    <el-radio-group v-model="currentQuestOption" @input="selectRes">
                      <el-radio :label="optItem.id" v-for="(optItem, opt) in item.options" :key="opt"
                        >{{ optItem.content.text }}
                        <div class="img-box flex-start flex-wrap" v-if="optItem.content.imgPath && optItem.content.imgPath.length > 0">
                          <img :src="`${Services.Statics}${img}`" v-for="(img, i) in optItem.content.imgPath" :key="i" />
                        </div>
                      </el-radio>
                    </el-radio-group>
                  </div> -->
                  <div class="testBtn-div" >
                  <el-button v-if="CurrentIndex >= 1" class="btn" @click="upQuestion(item)">
                    <span class="text">上一题</span>
                  </el-button>
                  <el-button v-if="CurrentIndex < QuestionTotal-1 && item.optionId" class="btn" @click="NextQuestion(item,index)">
                    <span class="text">下一题</span>
                  </el-button>
                  <el-button v-if="CurrentIndex == QuestionTotal-1 && CurrentQuestionLst[CurrentIndex].optionId" class="btn" @click="getQuestRes()">
                    <span class="text">查看结果</span>
                  </el-button>
                </div>
                </div>
       
              </div>
       
            </div>
 
            <!-- <div class="res-div flex-start flex-wrap">
              <div class="res-item" v-for="(item, index) in optionRes" :key="index" :class="{ resOk: item.optionId }" @click="showQuest(index)">{{ index + 1 }}</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
  </div>
</template>

<script>
import hdCom from "../../components/header";
import ftCom from "../../components/footer";
import rtBar from "../../components/right";
import { Test } from "../../components/company/test";
export default {
  name: "selfTest",
  props:['titleName'],
  components: {
    hdCom,
    ftCom,
    rtBar,
  },
  data() {
    var test = new Test(this.TokenClient, this.Services.Statics);
    return {
      testDomain: test,
      userInfo: this.$store.state.localData.userInfo,
      title: this.$route.query.titleName?this.$route.query.titleName:'',
      qId: 0,
      answerUser: {
        userId: 0,
        userName: "",
        answerUserName: "",
        answerSex: 0,
        answerAge: 0,
        answerPhone: "",
        diseaseName: "",
      }, //测试对象
      keyWord: "",
      answerUserList: [], //测试对象list
      addUser: 1,
      isStartTest: false, //是否开始测试
      selectAnswerUser: "", //测试对象
      currentQuestIndex: 0, //当前试题index
      currentQuestOption: "", //当前试题选中项id
      rules: {
        answerUserName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        answerPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" },
        ],
      },
      quesInfo: {
        questions: [],
      }, //试题集
      optionRes: [], //测试对象结果list
      isComplete: false,
      resInfo: {},
      noTexttype:'t0',
      AnswerStagingList:[],
      resultTrue:true,
      urlCheckM: require("@/assets/img/zdrugConsult/check.png"),
      noUrlCheckM: require("@/assets/img/zdrugConsult/znocheck.png"),
      urlCheck: require("@/assets/img/zdrugConsult/checkTrue.png"),
      noUrlCheck: require("@/assets/img/zdrugConsult/zradiono.png"),
      cssbtn:false,
      isgoId:'',
      isgoData:{},
      isgoDataList:[],
      CurrentQuestionLst:[],
      indexRa:-1,
      http:'',
      curOindex:-1,
      CurrentIndex:0,
      nextClick:true,
      userListChoose:''
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.isStartTest = false;
    this.getQuestionnaire(); //在mounted调用的methods里的方法
    next();
  },
  beforeDestroy (){
    if (!this.resultTrue && this.CurrentIndex > 0) {
        if (this.isgoId) {
				this.getupAnswerStaging()
			} else {
				this.getaddAnswerStaging()
			}
    }
  },
  created(){
    
  },
  mounted() {
    console.log(this.title)
    if(this.title==''){
    console.log(this.titleName)
      this.title=this.titleName
    }
    this.$bus.$emit("isShowChangeSize");
    this.http = this.Services.Statics;
    this.userListChoose = window.sessionStorage.getItem('bianzhenchooseUser') ? JSON.parse(window.sessionStorage.getItem('bianzhenchooseUser')) : ''
    this.getMyselfAnswerInfo();
    this.getQuestionnaire();
  },
  methods: {
    upQuestion(){
				var _this =this;
				if (_this.CurrentIndex > 0) {
					_this.CurrentIndex--;
					_this.curOindex = -1
				}
			},
      NextQuestion(item,index){
				var _this =this;
				if (item.optionId != undefined) {
					_this.CurrentIndex++;
				} else {
					_this.$refs.dialog.open()
					return;
				}
			},
    handleMultiple(index1,itemvalue,item1) {
				itemvalue.checked = !itemvalue.checked 
				// for(let i=0; i<this.CurrentQuestionLst.length; i++) {
				// 	for (let j=0; j<this.CurrentQuestionLst[i].options.length; j++) {
				// 		if (this.CurrentQuestionLst[i].options[j].id == itemvalue.id) {
				// 			this.CurrentQuestionLst[i].options[j].checked = !this.CurrentQuestionLst[i].options[j].checked
				// 		}
				// 	}
				// }
			},
    handleMultipleSure(index1,itemvalue,item) {
				let _this =this;
				let resultPush = []
				if(_this.nextClick == true) {
					_this.nextClick = false
					for (let i=0; i<_this.CurrentQuestionLst.length; i++) {
						if (_this.CurrentQuestionLst[i].id == item.id) {
							_this.CurrentQuestionLst[i].optionId = '123'
						}
					}
					_this.gonextque()
				}
				else {
					return
				}
		
		},
    handle(index,value,item){
      console.log()
      let _this =this;
      if(_this.nextClick == true) {
        _this.nextClick = false
        for (let i=0; i<_this.CurrentQuestionLst.length; i++) {
          if (_this.CurrentQuestionLst[i].id == item.id) {
            _this.CurrentQuestionLst[i].optionId = value
            _this.curOindex = index
            _this.gonextque()
          }
        }
      } else {
        return
      }
    },
    gonextque(){
      let _this =this;
      setTimeout(()=>{
        if(_this.CurrentIndex < _this.QuestionTotal-1){
          _this.CurrentIndex++
          _this.nextClick = true
          _this.curOindex = -1
          let newQuestionTotal = 0
          newQuestionTotal = _this.QuestionTotal -1
          _this.percentnum =  100 / newQuestionTotal * _this.CurrentIndex
        } else {
          _this.nextClick = true
        }
      },500)
    
    },
    gostartBox(item){
      this.resultTrue = false
      this.isgoId = item.id
      this.getDataList()
    },
    getDataList(){
      this.testDomain.getAnswerStaging(this.isgoId,
          res => {
            this.isgoData = res.data
						this.isgoDataList = JSON.parse(res.data.data)
            this.CurrentIndex = res.data.questionIndex
						this.getQuesList()
          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );
			},
    goalldelBox(){
      let params = []
				for (var i=0; i<this.AnswerStagingList.length; i++) {
					if (this.AnswerStagingList[i].checked) {
						params.push(this.AnswerStagingList[i].id)
					}
				}
      this.$confirm('确认删除此数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.testDomain.deleteAnswerStaging(
            params.toString(),
        res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getAnswerStagingInfoData()
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    godelBox(e){
      this.$confirm('确认删除此数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.testDomain.deleteAnswerStaging(
        e.id,
        res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getAnswerStagingInfoData()
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    allSelect(){
				this.cssbtn = !this.cssbtn
				if (this.cssbtn == true) {
					for (var i=0; i<this.AnswerStagingList.length; i++) {
						this.AnswerStagingList[i]['checked'] = true
					}
				} else {
					for (var i=0; i<this.AnswerStagingList.length; i++) {
						this.AnswerStagingList[i]['checked'] = false
					}
				}
		},
    goChooseCheck(e){
      for (let j = 0; j < this.AnswerStagingList.length; j++) {
					if(e.id == this.AnswerStagingList[j].id){
            this.AnswerStagingList[j].checked = !this.AnswerStagingList[j].checked
            // this.$set(this.AnswerStagingList[j], 'checked' , !this.AnswerStagingList[j].checked, )
					}
				}
				const allcartList = this.AnswerStagingList.every(item => {
					return item.checked == true
				})
				if (allcartList) {
					this.cssbtn = true
				} else {
					this.cssbtn = false
				}
    },
    getAnswerStagingInfoData() {
      this.testDomain.getAnswerStaging(
        this.noTexttype,
        res => {
          this.AnswerStagingList = res.data
          for(let i=0; i<this.AnswerStagingList.length; i++){
							this.AnswerStagingList[i].answerUserInfo.showanswerPhone =	this.AnswerStagingList[i].answerUserInfo.answerPhone.substr(0,3) + "****" + this.AnswerStagingList[i].answerUserInfo.answerPhone.substr(7)
							if (this.AnswerStagingList[i].answerUserInfo.answerAge == 0) {
								this.AnswerStagingList[i].answerUserInfo['ageContent'] = '6岁以下'
							} else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 1) {
								this.AnswerStagingList[i].answerUserInfo['ageContent'] = '7-12岁'
							} else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 2) {
								this.AnswerStagingList[i].answerUserInfo['ageContent'] = '13-17岁'
							} else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 3) {
								this.AnswerStagingList[i].answerUserInfo['ageContent'] = '18-45岁'
							} else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 4) {
								this.AnswerStagingList[i].answerUserInfo['ageContent'] = '46-69岁'
							} else {
								this.AnswerStagingList[i].answerUserInfo['ageContent'] = '69岁以上'
							}
							this.AnswerStagingList[i]['percentnum'] = 100 / this.AnswerStagingList[i].questionTotal * this.AnswerStagingList[i].questionIndex
							// this.AnswerStagingList[i]['checked'] = false
              this.$set(this.AnswerStagingList[i], 'checked' , false, )
						}
        },
        error => {
            this.AnswerStagingList = [];
        }
      );
    },
    goAnswerStaging(){
      if (this.AnswerStagingList.length == 0) {
        this.$message({
          message: '暂无未完成数据',
          type: 'warning'
        });
        return
      }
      this.addUser = 2
      this.getAnswerStagingInfoData()
    },
    search() {
      this.getMyselfAnswerInfo();
    },
    getQuestionnaire() {
      this.testDomain.Questionnaire(
        res => {
          var data = res.data.filter(i => i.name.includes(this.$route.query.titleName));
          if (this.$route.query.titleName.indexOf('中医体质') != -1) {
            this.noTexttype = 't0'
          }
          if (this.$route.query.titleName.indexOf('中医虚证') != -1) {
            this.noTexttype = 't1'
          }
          this.qId = data[0].id;
          this.getAnswerStagingInfoData()
          console.log(this.qId);
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    getupAnswerStaging(){
				let paramresList = []
				for (let j=0; j<this.CurrentIndex; j++) {
					paramresList.push(this.CurrentQuestionLst[j])
				}
				let resultParams = {}
				resultParams.id = this.isgoId
				resultParams.questionIndex = this.CurrentIndex
				resultParams.questionTotal = this.CurrentQuestionLst.length
				resultParams.data = JSON.stringify(paramresList)
        this.testDomain.putAnswerStaging(
        resultParams,
          res => {
            // this.$message({
            //   type: "success",
            //   message: "添加成功!",
            // });
          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );
			},
    getaddAnswerStaging(){
				let paramresList = []
				for (let j=0; j<this.CurrentIndex; j++) {
					paramresList.push(this.CurrentQuestionLst[j])
				}
        let resultParams = {}
        resultParams.answerId = this.qId * 1
				resultParams.answerName = this.title
				resultParams.questionIndex = this.CurrentIndex
				resultParams.questionTotal = this.CurrentQuestionLst.length
				resultParams.data = JSON.stringify(paramresList)
				resultParams.answerUserId = this.selectAnswerUser.id
        this.testDomain.addAnswerStaging(this.noTexttype,
        resultParams,
          res => {
            // this.$message({
            //   type: "success",
            //   message: "添加成功!",
            // });
          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );

    },
    /**
     * 获取测试结果
     */
    getQuestRes() {
      var _this  = this
      let userOptions = []
      for (let i=0; i<_this.CurrentQuestionLst.length; i++) {
				if (_this.CurrentQuestionLst[i].type == 'Multiple') {
					for(let j=0; j<_this.CurrentQuestionLst[i].options.length; j++) {
						if (_this.CurrentQuestionLst[i].options[j].checked == true) {
							userOptions.push({
								optionId:_this.CurrentQuestionLst[i].options[j].id,
								questionId: _this.CurrentQuestionLst[i].id
							})
						}
					}
				} else {
					if (_this.CurrentQuestionLst[i].optionId) {
						userOptions.push({
							optionId: _this.CurrentQuestionLst[i].optionId,
							questionId: _this.CurrentQuestionLst[i].id,
							// userId :1,
							// questionnaireResultId:1,
							// questionnaireId :parseInt(_this.Id)	
						})
					} else {
						userOptions.push({
							optionId: _this.CurrentQuestionLst[i].options[0].id,
							questionId: _this.CurrentQuestionLst[i].id,
							// userId :1,
							// questionnaireResultId:1,
							// questionnaireId :parseInt(_this.Id)	
						})
					}
				}
			
						
			}
      var userOptionInfo = {
        userOptions: userOptions,
        customerSex: this.selectAnswerUser.answerSex ? 1 : 0,
        customerName: this.selectAnswerUser.answerUserName,
        customerPhone: this.selectAnswerUser.answerPhone,
        diseaseName: this.selectAnswerUser.diseaseName,
        customerAge: this.selectAnswerUser.answerAge,
        questionnaireId: this.qId,
        isQRCode: 0, ////是否是通过扫码进来的 0表示否
        doctorId: "",
        userId: this.userInfo.id,
      };
      _this.testDomain.getUserResultId(
        userOptionInfo,
        res => {
          _this.resultTrue = true
						_this.goDelete()
            _this.$router.push({
            path: "/company/testResult",
            query: {
              resId: res.data,
              answerUserId: _this.selectAnswerUser.id,
            },
          });
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    goDelete(){
        this.testDomain.deleteAnswerStaging(this.isgoId,
          res => {

          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );
		},
    goTop() {
      document.documentElement.scrollTop = 0;
    },
    /**
     * 点击试题序号，显示相应试题
     * @param {*} index
     */
    showQuest(index) {
      this.currentQuestIndex = index;
      this.currentQuestOption = this.optionRes[this.currentQuestIndex].optionId;
      this.goTop();
    },
    /**
     * 选择答案
     */
    selectRes() {
      this.optionRes[this.currentQuestIndex].optionId = this.currentQuestOption;
      // 非最后一题
      if (this.currentQuestIndex != this.quesInfo.questions.length - 1) {
        this.currentQuestIndex++;
        this.currentQuestOption = this.optionRes[this.currentQuestIndex].optionId;
      }
      // 查询是否完成所有试题，OK显示查看结果btn
      if (this.optionRes.filter(x => x.optionId != "").length == this.optionRes.length) {
        this.isComplete = true;
      }
      this.goTop();
    },
    /**
     * 点击上一题/下一题
     * @param {*} tag
     */
    jumpQuest(tag) {
      this.optionRes[this.currentQuestIndex].optionId = this.currentQuestOption;
      if (tag == "last") {
        this.currentQuestIndex--;
      } else if (tag == "next") {
        this.currentQuestIndex++;
      }
      this.currentQuestOption = this.optionRes[this.currentQuestIndex].optionId;
      this.goTop();
    },
    stringToJSON(stringify) {
      return JSON.parse(stringify);
    },
    /**
     * 删除测试用户
     * @param {*} item
     * @param {*} i
     */
    delAnswerUser(item, i) {
      this.testDomain.RemoveAnswerUser(
        item.id,
        res => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.answerUserList.splice(i, 1);
          if (this.answerUserList.length == 0) {
            this.selectAnswerUser = "";
          }
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    editAnswerUser(item) {
      this.addUser = 0;
      this.answerUser.id = item.id;
      this.answerUser.userId = item.userId;
      this.answerUser.userName = item.userName;
      this.answerUser.answerUserName = item.answerUserName;
      this.answerUser.answerSex = item.answerSex ? 1 : 0;
      this.answerUser.answerAge = item.answerAge;
      this.answerUser.answerPhone = item.answerPhone;
      this.answerUser.diseaseName = item.diseaseName;
    },
    /**
     * 获取测试用户
     */
    getMyselfAnswerInfo() {
      this.testDomain.MyselfAnswerInfo(
        this.keyWord,
        res => {
          
          this.answerUserList = [];
          this.selectAnswerUser = {};
          this.answerUserList = res.data;
          // this.selectAnswerUser = this.answerUserList[0];
          this.answerUserList.forEach((item,index) => {
            if (item.answerAge == 0) {
              item.answerAgeDesc = "6岁以下";
            } else if (item.answerAge == 1) {
              item.answerAgeDesc = "7-12岁";
            } else if (item.answerAge == 2) {
              item.answerAgeDesc = "13-17岁";
            } else if (item.answerAge == 3) {
              item.answerAgeDesc = "18-45岁";
            } else if (item.answerAge == 4) {
              item.answerAgeDesc = "46-69岁";
            } else {
              item.answerAgeDesc = "69岁以上";
            }
            item.answerPhoneDesc = item.answerPhone.substr(0, 3) + "****" + item.answerPhone.substr(7);
            if (this.userListChoose.type == 'bianzhen') { 
              if (item.answerUserName == this.userListChoose.answerUserName && item.answerPhone == this.userListChoose.answerPhone && item.answerSex == this.userListChoose.answerSex && item.answerAge == this.userListChoose.answerAge && item.diseaseName == this.userListChoose.diseaseName) {
                this.selectAnswerUser = this.answerUserList[index]
              }
            } else {
              this.selectAnswerUser = this.answerUserList[0];
            }
          });
         
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    /**
     * 添加测试用户
     */
    saveBtn(formName) {
      this.$refs[formName].validate(val => {
        if (val) {
          if (this.answerUser.id) {
            this.testDomain.EditAnswerUser(
              this.answerUser,
              res => {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.addUser = 1;
              this.answerUser= {
                userId: 0,
                userName: "",
                answerUserName: "",
                answerSex: 0,
                answerAge: 0,
                answerPhone: "",
                diseaseName: "",
              },
                this.getMyselfAnswerInfo();

              },
              error => {
                console.log("请求失败!请刷新重试:", error);
              }
            );
          } else {
            this.answerUser.userId = this.userInfo.id;
            this.answerUser.userName = this.userInfo.userName;
            this.testDomain.AddAnswerUser(
              this.answerUser,
              res => {
                this.$message({
                  type: "success",
                  message: "添加成功!",
                });
                this.addUser = 1;
                this.answerUser= {
                userId: 0,
                userName: "",
                answerUserName: "",
                answerSex: 0,
                answerAge: 0,
                answerPhone: "",
                diseaseName: "",
              },
                this.getMyselfAnswerInfo();
              },
              error => {
                console.log("请求失败!请刷新重试:", error);
              }
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 开始测试
     */
    startTest() {
      this.resultTrue = false
      this.getQuesList();
    },
    /**
     * 查询试题
     */
    getQuesList() {
      var _this = this;
      _this.optionRes = [];
      _this.testDomain.getQuesList(
        _this.qId,
        _this.selectAnswerUser.answerSex ? 1 : 0,
        _this.selectAnswerUser.answerAge,
        res => {
          _this.quesInfo = res.data;
          if (_this.quesInfo.questions.length > 0) {
            _this.isStartTest = true;
            // if (this.isgoId) {
            //   this.isStartTest = true;
            //   for (let i = _this.currentQuestIndex; i < res.data.questions.length; i++) {
						// 		res.data.questions[i].content = JSON.parse(res.data.questions[i].content)
						// 		res.data.questions[i].content.imgPath = JSON.parse(res.data.questions[i].content.imgPath)
						// 		for (let j=0; j<res.data.questions[i].options.length; j++) {
						// 			res.data.questions[i].options[j].content = JSON.parse(res.data.questions[i].options[j].content)
						// 			res.data.questions[i].options[j].content.imgPath = JSON.parse(res.data.questions[i].options[j].content.imgPath)
						// 			if (res.data.questions[i].type == 'Multiple') {
						// 				_this.$set(res.data.questions[i].options[j],'checked', false)
						// 			}
						// 		}
            //     this.optionRes.push({
            //       optionId: res.data.questions[i].optionId,
            //       questionId: res.data.questions[i].id,
            //       questionnaireId: this.qId,
            //     });
						// 		_this.isgoDataList.push(res.data.questions[i])
						// 	}
            
            //   this.quesInfo.questions = _this.isgoDataList
            //   this.quesInfo.questions.forEach(item => {
            //     this.optionRes.push({
            //       optionId: item.optionId ? item.optionId : '',
            //       questionId: res.data.questions[i].id,
            //       questionnaireId: this.qId,
            //     });
            //   })

            // } else  {
            //   this.isStartTest = true;
            //   this.quesInfo.questions.forEach(item => {
            //     // 答题结果数组
            //     this.optionRes.push({
            //       optionId: "",
            //       questionId: item.id,
            //       questionnaireId: this.qId,
            //     });
            //     item.content = this.stringToJSON(item.content);
            //     if (item.content.imgPath) {
            //       item.content.imgPath = this.stringToJSON(item.content.imgPath);
            //     } else {
            //       item.content.imgPath = [];
            //     }
            //     item.options.forEach(opt => {
            //       opt.content = this.stringToJSON(opt.content);
            //       if (opt.content.imgPath) {
            //         opt.content.imgPath = this.stringToJSON(opt.content.imgPath);
            //       } else {
            //         opt.content.imgPath = [];
            //       }
            //     });
            //   });
            // }
            if (_this.isgoId) {
							_this.QuestionsList = res.data;
							_this.QuestionTotal =res.data.questions.length;
							for (let i = _this.CurrentIndex; i < res.data.questions.length; i++) {
								res.data.questions[i].content = JSON.parse(res.data.questions[i].content)
								res.data.questions[i].content.imgPath = JSON.parse(res.data.questions[i].content.imgPath)
								for (let j=0; j<res.data.questions[i].options.length; j++) {
									res.data.questions[i].options[j].content = JSON.parse(res.data.questions[i].options[j].content)
									res.data.questions[i].options[j].content.imgPath = JSON.parse(res.data.questions[i].options[j].content.imgPath)
									if (res.data.questions[i].type == 'Multiple') {
										_this.$set(res.data.questions[i].options[j],'checked', false)
									}
								}
								_this.isgoDataList.push(res.data.questions[i])
							}
							_this.CurrentQuestionLst = _this.isgoDataList
						} else {
							_this.QuestionsList = res.data;
							_this.QuestionTotal =res.data.questions.length;
							for(let i=0; i<res.data.questions.length; i++) {
								res.data.questions[i].content = JSON.parse(res.data.questions[i].content)
								res.data.questions[i].content.imgPath = JSON.parse(res.data.questions[i].content.imgPath)
								for (let j=0; j<res.data.questions[i].options.length; j++) {
									res.data.questions[i].options[j].content = JSON.parse(res.data.questions[i].options[j].content)
									res.data.questions[i].options[j].content.imgPath = JSON.parse(res.data.questions[i].options[j].content.imgPath)
									if (res.data.questions[i].type == 'Multiple') {
										_this.$set(res.data.questions[i].options[j],'checked', false)
									}
								}
							}
							_this.CurrentQuestionLst = res.data.questions
						}
          } else {
            _this.$message({
              type: "warning",
              message: "暂无匹配的试题!",
            });
          }
        },
        error => {
          _this.$message({
            type: "warning",
            message: "暂无匹配的试题!",
          });
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
  },
};
</script>

<style scoped>
::v-deep .el-progress__text {
  display: none;
}

.optionsImgbox {
  width: 245px;
  margin: 0 10px;
}
.question-option {
  margin-top: 20px;
  padding: 0 30px;
  cursor: pointer;
}
.optionsImgbox img {
  width: 100%;
  /* border-radius: 12rpx; */
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}
.titleBoxTextimage {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.titleBoxTextimage img {
  width: 100%;
  height: 100%;
}
.titleBoxText {
  font-size: 18px;
}
.titleBoxTextimage {
  width: 24px;
  height: 24px;
}
.titleBoxTextimage img {
  width: 100%;
  height: 100%;
}
.flex-s-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-s-b-r {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.delBox1 {
    width: 80px;
    /* height: 30px; */
		font-size: 16px;
		/* color: #FFFFFF; */
		/* background: #00C4AC; */
		border-radius: 15px;
		text-align: center;
    padding: 5px 0;
	}
  .delBox {
    width: 80px;
    /* height: 30px; */
		font-size: 16px;
		color: #FFFFFF;
		background: #00C4AC;
		border-radius: 15px;
		text-align: center;
    padding: 5px 0;
	}
  .userNameBox1 {
    font-size: 18px;
    color: #666666;
  }
  .selectUser-div .item-div1:hover {
  border: 1px solid #00cbb5 !important;
}
  .selectUser-div .item-div1 {
  width: 43%;
  height: 120px;
  margin-right: 2%;
  margin-bottom: 20px;
  border: 1px solid #e3e6e6;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 30px;
  cursor: pointer;
  align-items: flex-start !important;
  padding: 10px 20px;
  position: relative;
}
.selectUser-div .item-div1:nth-child(3n) {
  margin-right: 0;
}
.userNameBox {
  width: 120px;
  overflow: hidden;/*超出部分隐藏*/
  white-space: nowrap;/*不换行*/
  text-overflow:ellipsis;/*超出部分文字以...显示*/
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}
.content-wrap {
  background: #fff;
}
.userList-div {
  height:400px;
  overflow-y:auto
}
.zsureBtn {
  margin-right:20px;
  margin-top:20px
}
.content {
  width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 295px - 140px);
  padding: 30px 20px;
  background: url("../../assets/img/bg001.png") top;
  background-size: 100% 1050px;
}

.top-div {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.top-div .left-img {
  width: 24px;
  height: 24px;
  border: 5px solid #ebeefd;
  background: linear-gradient(90deg, #4db992, #81dfa7);
  box-shadow: 0px 3px 6px 0px rgba(18, 184, 149, 0.3);
  border-radius: 50%;
  margin-right: 10px;
}

.top-div .top-title {
  width: auto;
  height: 29px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}

.content .addUser-div {
  background: #fff;
  width: 80%;
  margin: 0 auto;
  height: auto;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 40px;
  padding: 20px 10px;
}

.content .addUser-div .addUser-box {
  background: #fff;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  border-radius: 40px;
}

.addUser-div .top-tip {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #db3a3a;
  margin-bottom: 20px;
}

.addUser-div .center-form {
  width: 100%;
}

.addUser-div .center-form /deep/ .el-form {
  width: 80%;
  margin: 0 auto;
}

.addUser-div .center-form /deep/ .el-form-item__label {
  color: #01c2ac;
  font-weight: 400;
  font-size: 17px;
}

.addUser-div .center-form .age-radio /deep/ .el-radio {
  line-height: 40px;
}

.addUser-div .center-form .age-radio {
  width: 80%;
}

.addUser-div .btn,
.selectUser-div .btn {
  width: 160px;
  height: 50px;
  background: linear-gradient(90deg, #03cdb4, #00a28e);
  box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.21);
  border-radius: 25px;
}
.addUser-div .btn1,
.selectUser-div .btn1 {
  width: 160px;
  height: 50px;
  background: #ffffff;
  /* box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.21); */
  border-radius: 25px;
}
.btn1 .text {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.btn .text {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.addUser-div .w-18 {
  width: 80%;
}

.selectUser-div {
  background: #fff;
  /* width: calc(80% - 60px); */
  margin: 0 auto;
  height: auto;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 40px;
  padding: 30px;
}

.selectUser-div .selectUser-box,
.test-div .test-box {
  width: 90%;
}

.selectUser-div .userList-div,
.test-div .testList-div {
  width: 100%;
}

.selectUser-div .active {
  border: 1px solid #00cbb5 !important;
}

.selectUser-div .item-div:hover {
  border: 1px solid #00cbb5 !important;
}

.selectUser-div .item-div {
  width: 26%;
  height: 112px;
  margin-right: 2.5%;
  margin-bottom: 20px;
  border: 1px solid #e3e6e6;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 30px;
  cursor: pointer;
  align-items: flex-start !important;
  padding: 10px 20px;
  position: relative;
}

.selectUser-div .item-div:nth-child(3n) {
  margin-right: 0;
}

.selectUser-div .userName {
  width: 100%;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 500;
}

.selectUser-div .item-div .addBtn {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #009583;
}

.selectUser-div .userInfo {
  color: #999999;
  font-size: 16px;
}

.selectUser-div .topDel-div {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #009583;
  cursor: pointer;
}
.searchInput ::v-deep() .el-input__inner {
  border-radius: 20px;
  height: 50px;
  line-height: 50px;
}
/* 测试题 */
.test-div {
  background: #fff;
  width: 80%;
  margin: 0 auto;
  height: auto;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 40px;
  padding: 30px 0;
}

.test-div .top-tip {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 20px;
}

.test-div .test-box {
  width: 90%;
}

.test-div .test-box .testTitle-bg {
  background: #fff;
  width: 95%;
  margin: 0 auto;
  height: auto;
  padding: 15px 2.5% 20px;
  background: #f5f5f5;
  border-radius: 12px;
}

.test-div .item-div {
  background: #fff;
  width: 100%;
  height: auto;
}

.test-div .quest-title {
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}

.test-div .img-box {
  /* width: 96%; */
  /* margin: 0 auto; */
  margin-top: 10px;
}

.test-div .img-box img {
  border-radius: 12px;
  width: auto;
  height: 100px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.test-div .test-option {
  width: 95%;
  margin: 0 auto;
  height: auto;
  padding: 20px 2.5% 10px 2.5%;
}

.test-div .test-option /deep/ .el-radio {
  width: 100%;
  margin: 10px auto 20px;
}

.test-div .test-option /deep/ .el-radio .el-radio__label {
  font-size: 25px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.test-div .test-option /deep/ .el-radio .el-radio__inner {
  width: 25px;
  height: 25px;
  border: 1px solid #666666;
  margin-top: -10px;
}

.test-div .test-option /deep/ .el-radio .el-radio__inner::after {
  width: 9px;
  height: 9px;
  left: 53%;
}

.test-div .testBtn-div {
  width: 95%;
  margin: 0 auto;
  height: auto;
  padding: 30px 2.5%;
}

.test-div .testBtn-div .btn {
  width: 120px;
  height: 40px;
  background: linear-gradient(90deg, #03cdb4, #00a28e);
  box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.15);
  border-radius: 20px;
}

.test-div .testBtn-div .btn span {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.test-div .res-div {
  width: 95%;
  margin: 0 auto;
  height: auto;
  padding: 30px 2.5%;
}

.test-div .res-div .res-item {
  width: 42px;
  height: 42px;
  line-height: 42px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin: 0 5px 5px 0;
  text-align: center;
  cursor: pointer;
}

.test-div .res-div .resOk {
  background: #d7fef9;
  border: 1px solid #00c1a9;
}
</style>
